import React, { useEffect, useState } from 'react';
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, navigate } from "gatsby";
import parse from 'html-react-parser';
import Helmet from 'react-helmet';

import "../assets/styles/blog.scss";

const Post = ({childIndex = null, child = false, data: {craft: {entries: [{title, id, slug, listingImage, blogSummary, blogContent, javascript, markup, libraries }], series}}}) => {  
  const [ loaded, setLoaded ] = useState(false);  
  const [ loadedArray, setLoadedArray ] = useState(libraries);  
  const checkLoaded = (i) => {
    const librariesLoaded = loadedArray.filter(item => item.loaded === true);
    if (librariesLoaded.length === libraries.length) {
      setLoaded(true);
    }else{
      loadLibrary(i + 1);  
    }
  }
  
  const loadLibrary = (i) => {
    //console.log(libraries);
    const library = libraries[i];        
    const assets = library.assets;
    for (let j = 0; j < assets.length; j++){
      const asset = assets[j];
      
      const scriptElem = document.getElementById(library.id);
      if (scriptElem === null && asset) {
        const script = document.createElement('script');
        script.id = library.id;
        script.src = asset.url;            
        script.async = true;
        script.onload = function() {                        
          loadedArray[i].loaded = true;
          setLoadedArray(loadedArray);                      
          checkLoaded(i);
        }
        document.body.append(script);        
      }else{
        loadedArray[i].loaded = true;
        setLoadedArray(loadedArray);                      
        checkLoaded(i);        
        
      }
    }
    
  }

  useEffect(() => {

    if (child === false && libraries && libraries.length){
      
      loadLibrary(0);  
    }else{
      setLoaded(true);
    }
    
  },[libraries]);

  const renderContent = () => {

    if (javascript || markup || (blogContent && blogContent.length > 0)){
      return (
        <div>
          <div className="callout">{ blogSummary }</div>          
          
          { blogContent && blogContent.map((module, i) => {
            if (module.wysiwyg){
              return parse(module.wysiwyg)
            }else if (module.embedCode){
              return <div className="flex-video">{ parse(module.embedCode) }</div>
            }
            return null;
          })}
          { (javascript || markup ) &&  renderCode() }
        </div>
      )
    }

    return (
      <div>
        <div className="callout">{ blogSummary }</div>
        { listingImage && listingImage.length > 0 &&
         <img src={ listingImage[0].url } alt="" className="image" />
        }
      </div>
    )
  }

  const renderCode = () => {    
    if (javascript && loaded) {
      if (child) javascript = javascript.replaceAll(/.p5Instance/ig, '.p5Instance' + childIndex);
      return (
        <div id={"content-" + id }>              
          <div dangerouslySetInnerHTML={{ __html: markup}}></div>          
          <Helmet>
            <script type="text/javascript">{ javascript.replaceAll(/content/ig,'content-' + id) }</script>                          
          </Helmet>
        </div>
      )
    }
    if (markup) return <div dangerouslySetInnerHTML={{ __html: markup}}></div>          
    return null;
  }  
 const Heading = `h${child ? 2 : 1}`;
  return (
    <Layout >      
      <div className="row blog detail" id={ slug }>
        <Heading >{ title }</Heading>
        { renderContent() }
        { loaded && series ? series.map((item, index) => {
          return <Post childIndex={ index } child={ true } key={ index } data={{ craft: { entries: [item] }}}/>
        }) : null }
        { !child &&  <button className="backLink link"  onClick={() => navigate(-1)}>Go back to blog listing</button>}             </div>
    </Layout>
  )
}

export default Post;

export const query = graphql`
  query ($id: [Craft_QueryArgument]) {
    craft {
      entries(id: $id) {
        title
          id
        ... on Craft_blog_posts_Entry {
          listingImage{
            url
          }
          blogSummary
          blogContent{
            ...BlogContent
          }

        }
        ... on Craft_blog_code_Entry {
          listingImage{
            url
          }
          javascript
          markup
          blogSummary
          blogContent{
            ...BlogContent
          }
          libraries {
            ... on Craft_libraries_Category {
              id
              title
              assets {
                url
              }
            }
          }

        }
      }
      series: entries(series: $id,orderBy:"postDate ASC") {
        title
          id      
        ... on Craft_blog_code_Entry {
          listingImage{
            url
          }
          javascript
          markup
          blogSummary
          blogContent{
            ...BlogContent
          }
          libraries {
            ... on Craft_libraries_Category {
              id
              title
              assets {
                url
              }
            }
          }

        }
      }
    }
  }

`;
